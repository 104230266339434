<template>
    <div class="description" :style="style">
        <p v-if="description && description !== 'null'">
            <span>{{ description }}</span>
            <span class="moreDesc" v-if="moreDesc" @click="moreless((content.longdescription || content.shortdescription), 170)">{{ showMore ? 'More' : 'Less' }}</span>
        </p>
    </div>
</template>
<script>
export default {
    name: 'Description',
    props: ['toggle', 'descStyle', 'whereIAmFrom', 'content'],
    data () {
        return {
            showMore: true,
            description: '',
            moreDesc: false,
        }
    },
    watch: {
        content () {
            this.spliceText((this.content?.longdescription || this.content?.shortdescription), 170);
        }
    },
    mounted () {
        this.spliceText((this.content?.longdescription || this.content?.shortdescription), 170);
    },
    methods: {
        moreless(text, maxLength) {
            this.showMore = !this.showMore;
            if(this.showMore){
                this.description = text?.slice(0, maxLength) + '...';
            } else {
                this.description = text + '.';
            }
        },
        spliceText(text, maxLength) {
            if(text && text !== 'null') {
                if (text?.length > maxLength) {
                    this.moreDesc = true;
                    this.description = text?.slice(0, maxLength) + '...'; 
                } else {
                    this.description = text + '.' ; 
                }
            }
        },
    },
    computed: {
      style () {
        return {
            width: `${this.descStyle.width}%`
        }
      }
    },
}

</script>
<style scoped lang="scss">
    .description {
        font-size: 1rem;
        font-weight: 400;
        // color: #929292;
    }
    .moreDesc {
      font-size: 0.6rem;
      border: #3b3a3a 0.8px solid;
      padding: 0.1rem 0.4rem;
      border-radius: 0.2rem;
      color: #ffffff;
      cursor: pointer;
    }
</style>